import { Injectable } from "@angular/core";
import { CodeAuth } from "agero-js-identity";
import { environment } from "../../../../environments/environment";
import { MatDialog } from "@angular/material";
import { SplunkService } from "./splunk.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { CacheService } from "./cache.service";
import { CasesearchService } from "./casesearch.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = window.location.origin + "/";
  postMessageInterval: any;
  counter: number = 0;
  maintenanceMessage = new BehaviorSubject(null);
  maintenanceMessageOBS = this.maintenanceMessage.asObservable();
  constructor(
    private casesearchservice: CasesearchService,
    private cache: CacheService,
    public dialog: MatDialog,
    private splunkService: SplunkService,
    private http: HttpClient,
    private router: Router
  ) { }
  public codeAuth = new CodeAuth({
    clientId: environment.ageroAuthConfig.clientId,
    appWebDomain: environment.ageroAuthConfig.appWebDomain,
    redirectUriSignIn:
      this.baseUrl + environment.ageroAuthConfig.redirectUriSignIn,
    redirectUriSignOut:
      this.baseUrl + environment.ageroAuthConfig.redirectUriSignOut,
    apiFullUrl: environment.ageroAuthConfig.apiFullUrl,
    refreshTimeoutOffset: environment.ageroAuthConfig.refreshTimeoutOffset,
    activityTimeout: environment.ageroAuthConfig.activityTimeout,
    useMultiTenantApi: environment.ageroAuthConfig.useMultiTenantApi,
    loginCallback: (value) => {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "Login Succsessful",
        logMessage: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: "User signed into Agero Apps successfully",
      };
      this.splunkService.splunkLog(logData);

    },
    refreshCallback: (value) => {


      this.sendTokenToAPPS();
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "I refreshed my token! callback",
        logMessage: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: "User succesfully refreshed the token",
      };
      this.splunkService.splunkLog(logData);
    },
    signOutCallback: (value) => {

      console.log("I logged out!" + value);
      localStorage.clear();
      let iframes = document.getElementsByTagName("iframe");
      for (let i = 0; i < iframes.length; i++) {
        let win = iframes[i].contentWindow;
        win.postMessage(
          JSON.stringify({ key: "ageroAuthToken", action: "remove" }),
          "*"
        );


      }
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "I logged out! callback",
        logMessage: value
          ? JSON.stringify(value)
          : this.codeAuth.getDecodedIdToken(),
        logStory: "User logged out successfully",
      };
      this.splunkService.splunkLog(logData);
    },
    errorCallback: (err) => {
      console.log("Error occurred!" + err);
      let logData = {
        /*send data to splunk*/
        logLevel: "Critical",
        logDescription: "Error occured",
        logMessage: "error is:" + err ? JSON.stringify(err) : "NA",
        logStory: "Error occured when user tried to login",
      };
      this.splunkService.splunkLog(logData);
    },
  });

  getSession() {
    return this.codeAuth.getSession();
  }
  parseRedirect(callBack: Function) {
    this.codeAuth.parseRedirect(callBack);
  }
  login() {
    console.log("Signining in", this.codeAuth.getSession())
    this.codeAuth.getSession();
  }
  signOut() {
    this.codeAuth.signOut();
  }
  isUserSignedIn(): boolean {
    return this.codeAuth.isUserSignedIn();
  }
  getDecodedIdToken() {
    const decodedToken = this.codeAuth.getDecodedIdToken();
    return decodedToken;
  }

  getRawIdToken() {
    const idToken = this.codeAuth.getRawIdToken();
    return idToken;
  }
  refreshToken(refreshCallBack: any) {
    return this.codeAuth.refreshToken(refreshCallBack);
  }
  activityTimer() {
    return this.codeAuth.resetActivityTimer();
  }

  private sendTokenToAPPS() {
    let a = this.getRawIdToken();
    let decodedToken = this.getDecodedIdToken();
    console.log("TOKEN", decodedToken);
    let programs = JSON.parse(localStorage.getItem("programs"));
    let obj = {
      name: a,
      decodedToken: decodedToken,
      programs: programs
    };
    let iframes = document.getElementsByTagName("iframe");
    for (let i = 0; i < iframes.length; i++) {
      let win = iframes[i].contentWindow;
      win.postMessage(
        JSON.stringify({ key: "ageroAuthToken", data: obj, action: "save" }),
        "*"
      );

    }
  }

  checkLogin() {
    if (this.isUserSignedIn()) {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "Check for isUserSignedIn",
        logMessage: "isUserSignedIn is successful - true",
        logStory: "Checking user signed in status : User is already signed in",
      };
      this.splunkService.splunkLog(logData);
      return true;
    } else {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "Check for isUserSignedIn",
        logMessage:
          "isUserSignedIn is successful - false, that leads to user to login page",
        logStory: "Checking user signed in status : User is not signed in",
      };
      this.splunkService.splunkLog(logData);
      this.login();
    }
  }

  private getSettings(settingName, settingValue): Observable<any> {
    let response;
    return this.http.get(`${environment.API.getSettingsApi}`).pipe(
      map((item: any) => {
        item.forEach((element) => {
          element.statusCategoryItems.find(function (data) {
            if (data.categoryItemName == settingName) {
              if (data.selected == settingValue) {
                return (response = {
                  settingName: settingName,
                  enabled: true,
                  additionalSettings: data.additionalSettings,
                });
              } else {
                return (response = {
                  settingName: settingName,
                  enabled: false,
                  additionalSettings: null,
                });
              }
            }
          });
        });

        return response;
      })
    );
  }




  public checkMaintenanceMode() {
    this.getSettings("Site Maintenance", "True").subscribe(

      (result) => {

        if (result.additionalSettings) {
          this.maintenanceMessage.next(result);
        }
        console.log(result, "result");
        if (result.enabled) {
          this.router.navigate(["maintenance"]);
        } else {
          this.router.navigate(["dashboard"]);
        }

      });
  }
}
