// Angular
import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { SplunkService } from "../_service/splunk.service";
import { Router } from "@angular/router";
import { AuthService } from "../_service/authentication.service";

@Component({
  selector: "app-session-timeout-confirmation",
  templateUrl: "session-timeout-confirmation-dialog.html",
})
export class SessionTimeoutConfirmationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionTimeoutConfirmationDialogComponent>,
    private splunkService: SplunkService,
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit() {
    let tokenData = this.authService.getDecodedIdToken();
    let reportUrl = this.router.url;
    let logData = {
      logLevel: "Info",
      logDescription: `User is inactive for 25mins , user details - Email:${tokenData.email}, First Name:${tokenData.given_name}, Last Name:${tokenData.family_name}`,
      logMessage: "url is:" + reportUrl,
      logStory:
        "Session timeout popup is displayed as the user is inactive for more than 25 mins",
    };
    this.splunkService.splunkLog(logData);
  }
}
