import { Component, OnInit } from "@angular/core";
import { AuthService } from "../dashboard/_service/authentication.service";
import { Router } from "@angular/router";
import { SplunkService } from "../dashboard/_service/splunk.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "callback",
  templateUrl: "./callback.component.html",
})
export class CallBackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private splunkService: SplunkService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.authorizeUser();
  }

  private authorizeUser() {
    if (this.authService.isUserSignedIn()) {
      /*prevent the user to goback to callback page after succesfull login*/
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "User is signIn already",
        logMessage: "So Redirect to dashboard",
        logStory:
          "As user is already logged in and redirecting to the dashboard",
      };
      this.splunkService.splunkLog(logData);
      this.router.navigateByUrl("/dashboard/home");
    } else {
      this.generateSession();
  
    }
  }

  private generateSession() {
    /* generate session for user */
    let callbackCode = this.route.snapshot.queryParamMap.get("code");
    this.authService.parseRedirect(() => {
      let logData = {
        /*send data to splunk*/
        logLevel: "Info",
        logDescription: "parseRedirect callback happend",
        logMessage: "callback code:" + callbackCode,
        logStory:
          "After generating session, redirecting to Agero apps dashboard",
      };
      this.splunkService.splunkLog(logData);
      console.log("parse redirect completed");
      let token = this.authService.getRawIdToken();
      if (token) {
        this.router.navigateByUrl("/dashboard/home");
      }
    });
  }
}
