import { Component, OnInit } from "@angular/core";
import { AuthService } from "../dashboard/_service/authentication.service";

@Component({
  selector: "logout",
  templateUrl: "./logout.component.html"
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) { }
  ngOnInit() {
    this.authService.login();
  }
}
