import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/_modules/dashboard/_service/authentication.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  styleUrls: ["./maintenance.component.css"],
})
export class MaintenanceComponent implements OnInit {
  primaryMSG: any;
  secondaryMSG: any;
  constructor(private auth: AuthService, private router: Router) {
    this.primaryMSG = "Under Maintenance";
    this.secondaryMSG =
      "This application is under maintenance Please wait for few minutes";
      this.auth.checkMaintenanceMode();
  }

  ngOnInit(): void {
    this.auth.maintenanceMessageOBS.subscribe((res) => {
      if (res) {
        this.primaryMSG = res.additionalSettings[1].Value;
        this.secondaryMSG = res.additionalSettings[2].Value;
      }
    });
  }

  refresh() {
    this.router.navigate(["dashboard"]);
  }
}
