import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthService } from "src/app/_modules/dashboard/_service/authentication.service";

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has("noContentType")) {
      if (!request.headers.has("ageroAuthToken")) {
        let ageroAuthToken = this.authService.getRawIdToken();
        request = request.clone({
          headers: request.headers.set("ageroAuthToken", ageroAuthToken),
        });
      }
      if (
        !request.headers.has("Authorization") &&
        !request.url.includes("timeranges")
      ) {
        request = request.clone({
          headers: request.headers.set("Authorization", environment.API.apikey),
        });
      }

      request = request.clone({
        headers: request.headers.set("Accept", "application/json"),
      });
    } else {
      request = request.clone({
        headers: request.headers.delete("noContentType"),
      });
    }
    return next.handle(request);
  }
}
