import { Component, OnInit } from "@angular/core";
import { SplunkService } from "./_modules/dashboard/_service/splunk.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  isOnline: boolean;
  constructor(private splunkService: SplunkService) {
    this.isOnline = navigator.onLine;
  }
  ngOnInit() {
    let reportUrl = window.location.href;
    let logData = {
      logLevel: "Info",
      logDescription: "Has been redirect to app component page",
      logMessage: "url is:" + reportUrl,
      logStory: "User has been redirected to the app component page",
    };
    this.splunkService.splunkLog(logData);
  }
}
