import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule  } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/modules/shared.module";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
import { LogoutComponent } from "./_modules/logout/logout.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { IdentityGuard } from "./_modules/dashboard/_guards/identity.guard";
import { LogoutGuard } from "./_modules/dashboard/_guards/logout.guard";
import { httpInterceptorProviders } from "./shared/_interceptor";
import { SessionTimeoutConfirmationDialogComponent } from "./_modules/dashboard/session-timeout/session-timeout-confirmation-dialog.component";
import { SplunkService } from "./_modules/dashboard/_service/splunk.service";
import { CallBackComponent } from "./_modules/callback/callback.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive"; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { UserActivityService } from "./_modules/dashboard/_service/userinactivity.service";
import  { LeavePageDialogComponent } from './_modules/dashboard/leave-page-alert/leave-page-dialog.component';
import { AuthService } from "./_modules/dashboard/_service/authentication.service";
import { APIInterceptor } from "./shared/_interceptor/API.interceptor";
import { CtiScreenpopDialogComponent } from "./_modules/dashboard/cti-screenpop-dialog/cti-screenpop-dialog.component";
import { WebsocketService } from './_modules/dashboard/_service/websocket.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    CoreModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  declarations: [
    AppComponent,
    LogoutComponent,
    CallBackComponent,
    SessionTimeoutConfirmationDialogComponent,
    LeavePageDialogComponent,
    CtiScreenpopDialogComponent    
  ],
  entryComponents: [SessionTimeoutConfirmationDialogComponent, LeavePageDialogComponent, CtiScreenpopDialogComponent],
  providers: [
    httpInterceptorProviders,
    IdentityGuard,
    LogoutGuard,
    UserActivityService,
    SplunkService,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    WebsocketService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
