// Angular
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { SplunkService } from "../_service/splunk.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-leave-page-dialog",
  templateUrl: "leave-page-dialog.html"
})
export class LeavePageDialogComponent implements OnInit {

  showReasons: boolean = false;
  reason: any;
  showError: boolean = false;
  dispatchAlertForm = new FormGroup({
    reason: new FormControl('', Validators.required)
  })
  constructor(
    private splunkService: SplunkService,
    private router: Router,
    public dialogRef: MatDialogRef<LeavePageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    let reportUrl = this.router.url;
    let logData = {
      logLevel: "Info",
      logDescription: this.data.title,
      logMessage: "url is:" + reportUrl,
      logStory: this.data.message
    };
    this.splunkService.splunkLog(logData);
  }

  saveAgentResposne() {
    if (this.showReasons) {
      this.showError = false
      let selectedReason = this.dispatchAlertForm.controls['reason'].value
      if (!selectedReason) {
        this.showError = true
        return
      }

      let selectedObj = this.data.abandonReasons.find(item => item.abandonReasonId === selectedReason)
      this.dialogRef.close(selectedObj);

      this.showReasons = false;
    } else {

      this.showReasons = true;
    }
   
    
  }
}
