<form [formGroup]="dispatchAlertForm" >
  <mat-dialog-content class="page-create-request items-inline">
  <!-- <div class="dialog-exit">
      <button type="button" [mat-dialog-close]="true" class="btn-x">&times;</button>
  </div> -->
  <div class="title-group">
    <h2 class="popuphead">
      <span class="material-icons">warning</span> {{data.title}}</h2>
  </div>
  <div class="items-inline gap-top" *ngIf="!showReasons">
    {{data.message}}
  </div>
  <div class="items-inline gap-top" *ngIf="showReasons">
    <p>{{data.abandonMessage}}</p>

    <mat-form-field class="reason"  >
      <mat-label>Reason *</mat-label>
      <mat-select formControlName="reason" name="reason" >
        <mat-option *ngFor="let reason of data.abandonReasons" [value]="reason.abandonReasonId">{{reason.abandonReason}}</mat-option>
      </mat-select>
    </mat-form-field>


  </div>
</mat-dialog-content>

<mat-dialog-actions style="margin-bottom:0;" >
  <button type="button" class="btn-complete-upload" (click)="showReasons= false" [mat-dialog-close]="false" >
    {{data.cancelBtnText}}
  </button>
  <button [type]="showError ? 'submit' : 'button'" class="btn-complete-upload red" (click)="saveAgentResposne()" >
    {{data.okBtnText}}
  </button>
  
</mat-dialog-actions>

</form>