import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { SessionTimeoutConfirmationDialogComponent } from "../session-timeout/session-timeout-confirmation-dialog.component";
import { MatDialog } from "@angular/material";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { AuthService } from "./authentication.service";
import { CacheService } from "../_service/cache.service";

@Injectable()
export class UserActivityService {
  idleState = "Not started.";
  timedOut = false;
  constructor(
    private Cache:CacheService,
    public dialog: MatDialog,
    private idle: Idle,
    private authService: AuthService
  ) {}

  public userActivity() {
    
    this.idle.setIdle(2); // in seconds
    this.idle.setTimeout(environment.ageroAuthConfig.activityTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
      this.authService.activityTimer();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out!";
      this.timedOut = true;
      
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = "You will time out in " + countdown + " seconds!";
      if (countdown == environment.popupTimer) {
        this.showSessionTimeoutConfirmationPopup();
      }

      if (countdown == 40) {
        
        localStorage.setItem("SessionExpired", "true");
      }
    });

    this.reset();
  }
  private reset() {
    this.idle.watch();
    this.idleState = "Started.";
    this.timedOut = false;
  }
  private showSessionTimeoutConfirmationPopup(): void {
    const dialogRef = this.dialog.open(
      SessionTimeoutConfirmationDialogComponent,
      {
        panelClass: "screen-dialog",
        autoFocus: false,
      }
    );
  }

  removeApplicationData() {
    localStorage.removeItem("programs");
    localStorage.removeItem("agentExtentionNumber");
    localStorage.removeItem("socketConnected");
    localStorage.removeItem("socketConnectedExtn");
    localStorage.removeItem("isAgentLoggedIn");
    localStorage.removeItem("newExtensionEntered");
    localStorage.removeItem("socketConnectedOnInit");
    let iframes = document.getElementsByTagName("iframe");
    for (let i = 0; i < iframes.length; i++) {
      let win = iframes[i].contentWindow;
      win.postMessage(
        JSON.stringify({ key: "ageroAuthToken", action: "remove" }),
        "*"
      );
      
       
    }
    setTimeout(() => {
      this.authService.signOut();
    }, 900);
  }
}
